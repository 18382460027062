<template>
  <div class="animated fadeIn">
    <DataViewWrapper
      :filters="filters"
      :table-columns="tableColumns"
      :model="model"
      :cell-classes="cellClasses"
    />
  </div>
</template>

<script>
import models from "@/models";

console.log("models.warehouse.productNeed", models.warehouse.productNeed);
export default {
  name: "ProductNeed",
  components: {},
  data: function() {
    return {
      model: models.warehouse.productNeed,
      filters: ["Finished Product", "Bottle Size", "Warehouse"],
      tableColumns: [
        "Finished Product",
        "Bottle Size",
        "Bottles",
        "Bottles WH",
        "Bottles Diff",
        "Cases",
        "Cases WH",
        "Cases Diff",
        "Warehouse"
      ],
      cellClasses: {
        "Bottles Diff": [
          {
            class: "bg-danger",
            condition: row => +row["Bottles Diff"] < 0
          },
          {
            class: "bg-success",
            condition: row => +row["Bottles Diff"] > 0
          }
        ],
        "Cases Diff": [
          {
            class: "bg-danger",
            condition: row => +row["Cases Diff"] < 0
          },
          {
            class: "bg-success",
            condition: row => +row["Cases Diff"] > 0
          }
        ]
      }
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>

<style></style>
